<template>
  <div>
    Home page
  </div>
</template>

<script>
import { BRow, BCol,BCard, BTab, BCardText,BTabs } from "bootstrap-vue";
import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'
import useJwt from "@/auth/jwt/useJwt";
import Select2 from 'v-select2-component'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

export default {
  components: {BRow,BCol,VuePdfApp,Select2,BCard,flatPickr,BTab, BCardText, BTabs, FeatherIcon},
  data() {
    return {
      
    };
  },
  watch:{
    
  },
  mounted () {
    
  },
  created() {
    
  },
  methods: {
    
  }
};
</script>
<style>
.pdf-app .toolbar{
  z-index: 100;
}
.select2-selection--single:before {
  content: "";
  position: absolute;
  right: 14px;
  top: 0;
  bottom: 0;
  width: 14px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDkgMSIgc3Ryb2tlPSIjMDBCMEVDIiBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==") center / contain no-repeat;
}
.select2-selection__arrow{
  background: none;
  display: none !important;
}
.vgt-table td,.vgt-table th{
  padding: 0.25em 0.35em !important;
  vertical-align: middle !important;
  font-size: 1rem;
}
.vgt-table th{
  text-align: center;
}
</style>
<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
